import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "../Styles/viewqr.css";
import { onMessageListener } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Logo from "../Assets/Logo.png";

const ViewQR = () => {
  const location = useLocation();
  const qrCodeUrl = location.state ? location.state.qrCodeUrl : null;
  const savedQRBlob = localStorage.getItem("qrCodeBlob");
  const qrContainerRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (savedQRBlob || qrCodeUrl) {
      const image = new Image();
      image.onload = () => {
        setImageLoaded(true);
      };
      image.src = savedQRBlob || qrCodeUrl;
    }
    setupMessageListener();
  }, [savedQRBlob, qrCodeUrl]);

  const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
  const companyName = companyDetails ? companyDetails.companyName : null;

  const setupMessageListener = () => {
    onMessageListener().then((payload) => {
      toast.info("Token details updated", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {},
      });
    });
  };

  const handleDownload = () => {
    if (imageLoaded) {
      html2canvas(qrContainerRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png", 0.5);
        const pdf = new jsPDF("p", "mm", "a8");
        pdf.addImage(
          imgData,
          "JPEG",
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        );
        pdf.save("qr-code.pdf");
      });
    } else {
      console.error("Image not loaded yet");
    }
  };

  return (
    <div className="qr-code-page">
      <Header />
      <Navbar />
      <div className="qr-main-page">
        <div className="profile-title">
          <h3>Download QR</h3>
        </div>
        <hr />
        <div className="qr-main">
          <div>
            <h1 className="qr-title">Generated QR Code</h1>

            <p className="companyname">{companyName}</p>
            {(savedQRBlob || qrCodeUrl) && imageLoaded ? (
              <div className="qr-container">
                <div
                  ref={qrContainerRef}
                  className="download-qr-div"
                  style={{ color: "white", width: "100%" }}
                >
                  <h3 className="company-scan">Scan Me</h3>
                  <div className="qr-image">
                    <img
                      src={savedQRBlob || qrCodeUrl}
                      alt="QR Code"
                      onLoad={() => setImageLoaded(true)}
                      style={{
                        borderRadius: "10px",
                        width: "230px",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
                      }}
                    />
                  </div>
                  <p className="qr-para">Scan this QR code for token</p>
                  <p className="qr-para2">Powered By</p>
                  <div className="view-qr-logo">
                    <img src={Logo} className="view-qr-logo"></img>
                  </div>
                </div>
              </div>
            ) : (
              <p>No generated QR code available</p>
            )}
            <div className="download-section">
              <button className="download-button" onClick={handleDownload}>
                Download QR code as PDF
              </button>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ViewQR;
