import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axiosInstance from "../axiosInstance";
import ApiRoutes from "../ApiRoutes";
import { toast } from "react-toastify";
import "../Components/timeslot.css";

const localizer = momentLocalizer(moment);

const TimeSlotsCalendar = ({ isSlotAdded }) => {
  const [events, setEvents] = useState([
    {
      title: "My Event",
      start: new Date(2024, 0, 15, 10, 0),
      end: new Date(2024, 0, 15, 12, 0),
    },
  ]);
  const [showPopup, setShowPopup] = useState(false);
  const [slotData, setSlotData] = useState({
    slotName: "",
    startTime: "",
    endTime: "",
    slotAvailability: " ",
  });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchSlotsData() {
      try {
        const companyDetails = JSON.parse(
          localStorage.getItem("companyDetails")
        );
        const queuesArray = companyDetails?.queues || [];
        const queueId = queuesArray.length > 0 ? queuesArray[0].queueId : null;

        if (queueId !== null) {
          const response = await axiosInstance.get(
            `${ApiRoutes.apiBaseUrl}/company/workflow/get-slots/${queueId}`
          );

          const formattedEvents = response.data.map((slot) => ({
            id: slot.slotId,
            title: slot.slotName,
            start: moment(slot.slotStartTime, "HH:mm:ss").toDate(),
            end: moment(slot.slotEndTime, "HH:mm:ss").toDate(),
            slotAvailable: slot.slotAvailable,
          }));

          setEvents([...events, ...formattedEvents]);
        } else {
          console.error("No queueId found in companyDetails!");
        }
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    }

    fetchSlotsData();
    // eslint-disable-next-line
  }, [isSlotAdded]);

  const handleSelectSlot = (slotInfo) => {
    
    if (slotInfo && slotInfo.id) {
      setSelectedSlot(slotInfo);
      setSlotData({
        slotName: slotInfo.title,
        startTime: moment(slotInfo.start).format("HH:mm"),
        endTime: moment(slotInfo.end).format("HH:mm"),
        slotAvailability: slotInfo.slotAvailable ? "available" : "unavailable",
      });
    } else {
      setSelectedSlot(null);
      setSlotData({
        slotName: "",
        startTime: moment(slotInfo.start).format("HH:mm"),
        endTime: moment(slotInfo.end).format("HH:mm"),
        slotAvailability: "available",
      });
    }

    setShowPopup(true);
  };

  const addSlot = async () => {
    try {
      setLoading(true);
      const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
      const queuesArray = companyDetails?.queues || [];
      const queueId = queuesArray.length > 0 ? queuesArray[0].queueId : null;
      

      if (queueId !== null) {
        const startTime = moment(slotData.startTime, "HH:mm");
        const endTime = moment(slotData.endTime, "HH:mm");
  
        
        const workingStartTime = moment(companyDetails.queues[0].businessStartTime, "HH:mm");
        const workingEndTime = moment(companyDetails.queues[0].businessEndTime, "HH:mm");
  
        if (startTime.isBefore(workingStartTime) || endTime.isAfter(workingEndTime)) {
          toast.error("Slot time should be within working hours.");
          return;
        }
        if (!slotData.slotName.trim()) {
          toast.error("Slot name cannot be empty");
          setLoading(false);
          return;
        }
        const payload = {
          queueId,
          slotName: slotData.slotName,
          slotAvailable: slotData.slotAvailability === "available",
          slotStartTime: {
            hour: moment(slotData.startTime, "HH:mm").format("HH"),
            minute: moment(slotData.startTime, "HH:mm").format("mm"),
            nano: 0,
            second: "00",
          },
          slotEndTime: {
            hour: moment(slotData.endTime, "HH:mm").format("HH"),
            minute: moment(slotData.endTime, "HH:mm").format("mm"),
            nano: 0,
            second: "00",
          },
        };

        await axiosInstance.post(
          `${ApiRoutes.apiBaseUrl}/company/workflow/create-slot`,
          payload
        );

        const response = await axiosInstance.get(
          `${ApiRoutes.apiBaseUrl}/company/workflow/get-slots/${queueId}`
        );

        const formattedEvents = response.data.map((slot) => ({
          id: slot.slotId,
          title: slot.slotName,
          start: moment(slot.slotStartTime, "HH:mm:ss").toDate(),
          end: moment(slot.slotEndTime, "HH:mm:ss").toDate(),
          slotAvailable: slot.slotAvailable,
        }));

        setEvents([...events, ...formattedEvents]);
        toast.success("Slot added successfully");
        setShowPopup(false);
      } else {
        toast.error("Error in fetching queue details. Try again.");
      }
    } catch (error) {
      console.error("Error adding slot:", error);
      toast.error(`${error.response.data}`);
    }
    finally{
      setLoading(false);
    }
  };

  const deleteSlot = async () => {
    try {
      const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
      const queuesArray = companyDetails?.queues || [];
      const queueId = queuesArray.length > 0 ? queuesArray[0].queueId : null;

      if (queueId !== null && selectedSlot) {
        const slotId = selectedSlot.id;

        await axiosInstance.post(
          `${ApiRoutes.apiBaseUrl}/company/workflow/delete-slot`,
          { queueId, slotId }
        );

        const updatedEvents = events.filter(
          (event) => event.id !== selectedSlot.id
        );
        toast.success("Slot deleted successfully");
        setEvents(updatedEvents);
        setShowPopup(false);
      } else {
        toast.error("error in fetching slot details try again");
      }
    } catch (error) {
      console.error("Error deleting slot:", error);
      toast.error(`${error.response.data}`);
    }
  };

  const updateSlot = async () => {
    try {
      const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
      const queuesArray = companyDetails?.queues || [];
      const queueId = queuesArray.length > 0 ? queuesArray[0].queueId : null;

      if (queueId !== null && selectedSlot) {
        const slotId = selectedSlot.id;

        const startTime = moment(slotData.startTime, "HH:mm");
        const endTime = moment(slotData.endTime, "HH:mm");

        const workingStartTime = moment(companyDetails.queues[0].businessStartTime, "HH:mm");
        const workingEndTime = moment(companyDetails.queues[0].businessEndTime, "HH:mm");
    
        if (startTime.isBefore(workingStartTime) || endTime.isAfter(workingEndTime)) {
          toast.error("Slot time should be within working hours.");
          return;
        }
        if (!slotData.slotName.trim()) {
          toast.error("Slot name cannot be empty");
          return;
        }

        const payload = {
          queueId,
          slotId,
          slotName: slotData.slotName,
          slotAvailable: slotData.slotAvailability === "available",
          slotStartTime: {
            hour: moment(slotData.startTime, "HH:mm").format("HH"),
            minute: moment(slotData.startTime, "HH:mm").format("mm"),
            nano: 0,
            second: "00",
          },
          slotEndTime: {
            hour: moment(slotData.endTime, "HH:mm").format("HH"),
            minute: moment(slotData.endTime, "HH:mm").format("mm"),
            nano: 0,
            second: "00",
          },
        };

        await axiosInstance.post(
          `${ApiRoutes.apiBaseUrl}/company/workflow/update-slot`,
          payload
        );

        const updatedEvents = events.map((event) =>
          event.id === selectedSlot.id
            ? {
                ...event,
                title: slotData.slotName,
                start: moment(slotData.startTime, "HH:mm").toDate(),
                end: moment(slotData.endTime, "HH:mm").toDate(),
                slotAvailable: slotData.slotAvailability === "available",
              }
            : event
        );
        toast.success("Slot updated successfully");
        setEvents(updatedEvents);
        setShowPopup(false);
      } else {
        console.error(
          "No queueId found in companyDetails or no selected slot!"
        );
      }
    } catch (error) {
      console.error("Error updating slot:", error);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSlotData({
      ...slotData,
      [name]: value,
    });
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    const isAvailable = event.slotAvailable;
    const backgroundColor = isAvailable ? "#1e6620" : "#f44336";

    const style = {
      backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };

    return {
      style,
    };
  };

  return (
    <div className="time-slots-calendar">
  
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="day"
        style={{ height: 400 }}
        toolbar={false}
        selectable={true}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={(event) => handleSelectSlot(event)}
        eventPropGetter={eventStyleGetter}
      />
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content scale-up">
          {loading && (
            <div className="loader-overlay">
              <div className="loader-content">
                <div className="loader-spinner"></div>
                <p>Loading...</p>
              </div>
            </div>
          )}
            <div className="slot-form">
              <h2>{selectedSlot ? "Update Slot" : "Add Slot"}</h2>
              <div className="slotName-section">
                <label className="slot-label">Slot Name </label>
                <input
                  className="slot-input"
                  type="text"
                  id="slotName"
                  name="slotName"
                  placeholder="Enter slot name"
                  value={slotData.slotName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="slotName-section">
                <label className="time-section">
                  Type
                  <select
                    className="availabiity-dropdown"
                    id="slotAvailability"
                    name="slotAvailability"
                    value={slotData.slotAvailability}
                    onChange={handleInputChange}>
                    <option value="available">Available</option>
                    <option value="unavailable">Unavailable</option>
                  </select>{" "}
                </label>
              </div>
              <div className="slotTime-section">
                <label className="time-section">
                  Time{" "}
                  <input
                    className="slot-time-section"
                    type="time"
                    id="startTime"
                    name="startTime"
                    value={slotData.startTime}
                    onChange={handleInputChange}
                  />
                  <input
                    className="slot-time-section"
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={slotData.endTime}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
            </div>
            <div className="update-btn-div1">
              <button className="close-button1" onClick={closePopup}>
                Cancel
              </button>
              <button
                className="close-button2"
                onClick={selectedSlot ? updateSlot : addSlot}>
                {selectedSlot ? "Update" : "Add"}
              </button>
              {selectedSlot && (
                <button className="close-button3" onClick={deleteSlot}>
                  Delete Slot
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSlotsCalendar;
