import React from "react";
import { useState } from "react";
import Header from "../Components/Header";
import NavBar from "../Components/Navbar";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "../axiosInstance.js";
import { HttpStatusCode } from "axios";
import { handleLogout } from "../utils/index.js";
import { useNavigate } from "react-router-dom";

function UpdatePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleUpdatePasswordSubmit = async () => {
    setLoading(true);

    if (!newPassword && !confirmPassword) {
      toast.error("Please enter the password and confirm it ");
      setLoading(false);
      return;
    }
    if (newPassword && !confirmPassword) {
      toast.error("Please confirm the entered password ");
      setLoading(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match. Please re-enter.");
      setLoading(false);
      return;
    }

    const validatePassword = (password) => {
      const errors = [];

      if (!/(?=.*[a-z])/.test(password)) {
        errors.push("one lowercase letter");
      }
      if (password.length < 8) {
        errors.push("8 characters long");
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        errors.push("one uppercase letter");
      }
      if (!/(?=.*\d)/.test(password)) {
        errors.push("one number");
      }
      if (!/(?=.*[!@#$%^&*()])/.test(password)) {
        errors.push("one symbol");
      }

      if (errors.length > 0) {
        toast.error(`Password must contain at least ${errors.join(", ")} `);
        return false;
      }

      return true;
    };

    if (!validatePassword(newPassword)) {
      setLoading(false);
      return;
    }
    try {
      const companyDetailsString = localStorage.getItem("companyDetails");

      if (companyDetailsString) {
        let companyUserId = parseFloat(localStorage.getItem("companyUserId"));
        if (isNaN(companyUserId)) {
          const companyDetails = JSON.parse(
            localStorage.getItem("companyDetails")
          );

          const email = companyDetails.adminEmail;
          const response = await axiosInstance.get(
            `company/company-user-role/${email}`
          );
          if (response.status === 200) {
            companyUserId = response.data.companyUserId;
          } else {
            console.log("Company user id is::", companyUserId);
          }
        } else {
          console.log("Company user id:", companyUserId);
        }
        const companyId = parseFloat(localStorage.getItem("companyId"));

        const payload = {
          companyUserId: companyUserId,

          password: newPassword,
          companyId: companyId,
        };

        const response = await axiosInstance.post(
          "/company/update-company-user",

          payload
        );

        if (response.status === 200 || response.status === 204) {
          toast.success("Password updated successfully!");
          setNewPassword("");
          setConfirmPassword("");
          setLoading(false);
        } else {
          console.error(
            "Failed to update password. Unexpected status:",
            response.status
          );
        }
      } else {
        console.error("No company details found in local storage");
      }
    } catch (error) {
      if (error.response.status === HttpStatusCode.Locked) {
        // log off the user // forcefull log off occured.
        toast.error(error.response.data);
        handleLogout();
        navigate("/");
      }
      console.error("Error updating password:", error);
    }
  };

  return (
    <div>
      <Header />
      <div style={{ paddingTop: "4rem" }}>
        <NavBar />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="popup-content">
          {/* Loader */}
          {loading && (
            <div className="loader-overlay">
              <div className="loader-content">
                <div className="loader-spinner"></div>
                <p className="loading-text">Loading . . .</p>
              </div>
            </div>
          )}
          <div
            style={{
              border: "2px solid #3d4493",
              padding: "40px",
              borderRadius: "10px",
            }}
          >
            <h2 className="popup-title">Update Password</h2>
            <div className="update-form-input">
              <label className="label-popup">New Password *</label>
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                style={{ marginBottom: "2rem" }}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <label className="label-popup">Confirm Password *</label>
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                style={{ marginBottom: "2rem" }}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <div className="update-btn-div">
              <button
                className="inform-button"
                onClick={handleUpdatePasswordSubmit}
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default UpdatePassword;
