import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../Styles/profile.css";
import ApiRoutes from "../ApiRoutes.js";
import { onMessageListener } from "../firebase";
import axiosInstance from "../axiosInstance.js";
import search from "../Assets/search.png";
import Header from "../Components/Header.js";
import NavBar from "../Components/Navbar.js";

function CompanyProfile() {
  const [formData, setFormData] = useState({
    companyName: "",
    businessSubtypeName: "Select Business type",
    streetAddress: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    website: "",
    contactEmail: "",
    logoURL: "",
  });
  const [businessSubtypes, setBusinessSubtypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formEdited, setFormEdited] = useState(false);

  const setupMessageListener = () => {
    onMessageListener().then((payload) => {
      toast.info("Token updated", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {},
      });
    });
  };

  const fetchBusinessSubtypes = () => {
    axiosInstance
      .get(`${ApiRoutes.apiBaseUrl}/company/business-subtypes`)
      .then((response) => {
        const businessSubtypesData = response.data;
        setBusinessSubtypes(businessSubtypesData);

        const subtypesAsOptions = businessSubtypesData.map((subtype) => ({
          value: subtype.businessSubTypeName,
          label: subtype.businessSubTypeName,
        }));

        setFormData((prevFormData) => ({
          ...prevFormData,
          businessSubtypes: subtypesAsOptions,
        }));
      })
      .catch((error) => {
        console.error("Error fetching business subtypes:", error);
      });
  };
  const handlePincodeSearch = () => {
    if (formData.pincode) {
      axios
        .get(
          `https://nominatim.openstreetmap.org/search?postalcode=${formData.pincode}&format=json`
        )
        .then((response) => {
          if (response.data.length > 0) {
            const lat = response.data[0].lat;
            const lon = response.data[0].lon;

            axios
              .get(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&addressdetails=1`
              )
              .then((response) => {
                const address = response.data.address;
                setFormData((prevData) => ({
                  ...prevData,
                  streetAddress: address.village || "",
                  district: address.state_district || "",
                  state: address.state || "",
                  city: address.county || "",
                  country: address.country || "",
                  pincode: address.postcode || "",
                }));
              })
              .catch((error) => {
                console.error("Error fetching address details:", error);
              });
          } else {
            toast.error("No location found for the entered pincode.");
          }
        })
        .catch((error) => {
          console.error("Error fetching location details:", error);
        });
    } else {
      toast.error("Please enter a pincode to search.");
    }
  };

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");

    if (companyId) {
      setLoading(true);
      axiosInstance
        .get(`${ApiRoutes.apiBaseUrl}/company/${companyId}`)
        .then((response) => {
          const companyDetails = response.data;

          setFormData({
            companyName: companyDetails.companyName || "",
            businessSubtypeName:
              companyDetails.businessSubtype.businessSubTypeName ||
              "Select Business Subtype",
            streetAddress: companyDetails.companyAddress?.streetAddress || "",
            city: companyDetails.companyAddress?.city,
            pincode: companyDetails.companyAddress?.pinCode,
            country: companyDetails.companyAddress?.country,
            state: companyDetails.companyAddress?.state,
            website: companyDetails.urlWebSite,
            contactEmail: companyDetails.companyAddress?.contactEmail,
            logoURL: companyDetails.urlLogo,
            businessSubtypeId:
              companyDetails.businessSubtype.businessSubTypeId || "",
          });

          localStorage.setItem(
            "companyDetails",
            JSON.stringify(companyDetails)
          );
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
          setLoading(false);

        });
      fetchBusinessSubtypes();
    }
    setupMessageListener();
  }, []);

  const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
  const isAdminEmailPresent = companyDetails && companyDetails.adminEmail;
  const isCompanyAddressPresent =
    companyDetails && companyDetails.companyAddress;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormEdited(true);

    if (name === "businessSubtype") {
      const selectedSubtype = businessSubtypes.find(
        (subtype) => subtype.businessSubTypeName === value
      );

      if (selectedSubtype) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          businessSubtypeName: value,
        }));

        axiosInstance
          .get(`${ApiRoutes.apiBaseUrl}/company/business-subtype-id`, {
            params: {
              businessSubTypeName: value,
            },
          })
          .then((response) => {
            const subtypeId = response.data;
            localStorage.setItem("subtypeId", subtypeId);
            setFormData((prevFormData) => ({
              ...prevFormData,
              businessSubTypeId: subtypeId,
            }));
          })
          .catch((error) => {
            console.error("Error fetching business subtype ID:", error);
          });
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  // const handleLogoDisplay = () => {
  //   if (formData.logoURL) {
  //     setShowLogo(true);
  //   }
  // };

  useEffect(() => {}, [formData]);

  const saveCompanyInfo = () => {
    if (!formData.companyName.trim()) {
      toast.error("Company Name is required");
      return;
    }
    

    const validateEmail = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    if (formData.contactEmail && !validateEmail(formData.contactEmail)) {
      toast.error("Enter a valid email address");
      return;
    }
    const isValidPincode = /^[1-9][0-9]{4,8}$/.test(formData.pincode);
    if (formData.pincode && !isValidPincode) {
      toast.error("Enter a valid PIN / ZIP code");
      return;
    }
    setLoading(true);
    const requiredFields = ["companyName"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      toast.error("Enter the company name");
      return;
    }
    const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

    const adminEmail = companyDetails.adminEmail;
    const addressId = companyDetails.companyAddress.companyID;
    const updatedCompanyData = {
      adminEmail: adminEmail,
      businessSubTypeId: parseInt(localStorage.getItem("subtypeId")),
      companyAddress: {
        city: formData.city,
        companyAddress1: formData.streetAddress,
        companyAddress2: "",
        companyID: addressId,
        contactEmail: formData.contactEmail,
        contactPhone: "",
        country: formData.country,
        pinCode: formData.pincode,
        state: formData.state,
        streetAddress: formData.streetAddress,
      },
      companyId: parseInt(localStorage.getItem("companyId")),
      companyName: formData.companyName,
      urlLogo: formData.logoURL || "",
      urlWebSite: formData.website,
    };

    // if (formData.website && !/^https?:\/\/.+/.test(formData.website)) {
    //   toast.error("Enter a valid website URL");
    //   return;
    // }

    axiosInstance
      .put(`${ApiRoutes.apiBaseUrl}/company/update`, updatedCompanyData)
      .then((response) => {
        setFormData((prev) => ({
          ...prev,
          ...response.data.companyAddress,
        }));
        localStorage.setItem("companyDetails", JSON.stringify(response.data));
        toast.success("Company profile updated successfully");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Company profile update failed");
        console.error("Error updating company profile:", error);
      });
  };

  return (
    <div className="company-profile-container">
      <Header />
      <NavBar />
      <div className="profile-title">
        <h3>
          <span>Company Profile</span>
        </h3>
      </div>
      {/* Loader */}
      {loading && (
        <div className="loader-overlay">
          <div className="loader-content">
            <div className="loader-spinner"></div>
            <p className="loading-text">Loading . . .</p>
          </div>
        </div>
      )}
      <hr className="hr-profile" />
      <div className="top-section">
        <div>
          <h3 className="profile-sub-title">Company Details</h3>
          <div className="left-section">
            <div>
              <div className="logo-box">
                <img
                  id="logo"
                  className="logo-image"
                  src={formData.logoURL}
                  alt=""
                />
              </div>
              <label className="profile-label">Company Logo</label>

              <div className="url-section">
                <input
                  className="profile-input"
                  type="text"
                  name="logoURL"
                  placeholder="Paste the Logo URL"
                  value={formData.logoURL}
                  onChange={handleChange}
                />
              </div>
              <label className="profile-label">Company Name *</label>
              <input
                className="profile-input"
                placeholder="Company Name"
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
              <label className="profile-label">Business type</label>
              <select
                className="profile-input-select"
                name="businessSubtype"
                value={formData.businessSubtypeName}
                onChange={handleChange}
              >
                <option value="Select Business Subtype">
                  Select Business type
                </option>
                {businessSubtypes &&
                  businessSubtypes.map((subtype) => (
                    <option
                      key={subtype.businessSubTypeId}
                      value={subtype.businessSubTypeName}
                    >
                      {subtype.businessSubTypeName}
                    </option>
                  ))}
              </select>
              <label className="profile-label">Website</label>
              <input
                className="profile-input"
                type="text"
                placeholder="website"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
              <label className="profile-label">Email</label>
              <input
                className="profile-input"
                type="text"
                placeholder="Email"
                name="contactEmail"
                value={formData.contactEmail}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div>
          <h3 className="profile-sub-title">Address Details</h3>
          <div className="right-section">
            <div>
              <label className="profile-label">Pincode / Zip code</label>
              <div className="url-section">
                <input
                  className="url-input"
                  type="number"
                  name="pincode"
                  placeholder="Pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
                <img
                  className="url-icon"
                  src={search}
                  alt=""
                  onClick={handlePincodeSearch}
                ></img>
              </div>
              <label className="profile-label">Street Name</label>
              <input
                className="profile-input"
                type="text"
                name="streetAddress"
                placeholder="Street Name"
                value={formData.streetAddress}
                onChange={handleChange}
              />
              <label className="profile-label">Country</label>
              <input
                className="profile-input"
                type="text"
                name="country"
                placeholder="country"
                value={formData.country}
                onChange={handleChange}
              />

              <label className="profile-label">City</label>
              <input
                className="profile-input"
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
              />
              <label className="profile-label">State</label>
              <input
                className="profile-input"
                type="text"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="bottom-section">
            <div className="save-button-container">
              {formEdited &&
                (isAdminEmailPresent && isCompanyAddressPresent ? (
                  <button
                    className="profile-update-btn"
                    onClick={saveCompanyInfo}
                  >
                    Update Profile
                  </button>
                ) : (
                  <button
                    className="profile-update-btn"
                    onClick={saveCompanyInfo}
                  >
                    Save
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
      <hr />

      <ToastContainer />
    </div>
  );
}
export default CompanyProfile;
