import { RouterProvider } from "react-router-dom";
import { router } from "../src/routes/routes";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Latitude:", position.coords.latitude);
          console.log("Longitude:", position.coords.longitude);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error("User denied the request for geolocation.");
			  alert("To make the most of your experience, this website requires location service to be turned on. Follow these simple steps:\n\n1. Click on the Location icon located next to the URL in your browser.\n2. Choose 'Allow' to ensure you receive timely updates."
			  );
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("Location information is unavailable.");
			  alert("To make the most of your experience, this website requires location service to be turned on. Follow these simple steps:\n\n1. Click on the Location icon located next to the URL in your browser.\n2. Choose 'Allow' to ensure you receive timely updates."
			  );
              break;
            case error.TIMEOUT:
              console.error("The request to get user location timed out.");
			  alert("To make the most of your experience, this website requires location service to be turned on. Follow these simple steps:\n\n1. Click on the Location icon located next to the URL in your browser.\n2. Choose 'Allow' to ensure you receive timely updates."
			  );
              break;
            default:
              console.error("An unknown error occurred.");
			  alert("To make the most of your experience, this website requires location service to be turned on. Follow these simple steps:\n\n1. Click on the Location icon located next to the URL in your browser.\n2. Choose 'Allow' to ensure you receive timely updates."
			  );
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return <RouterProvider router={router} />;
}

export default App;

