import { useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance.js";
import ApiRoutes from "../ApiRoutes.js";
import { toast, ToastContainer } from "react-toastify";

function UserVerfication() {
  const [params, setParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        setLoading(true);
        const email = params.get("email");

        // Make API call to check verification status
        const response = await axiosInstance.get(
          `company/company-user-role/${email}`
        );

        // Check if userStatus is 0
        if (response.data.userStatus === 0) {
          setLoading(false);
        } else {
          toast.success("User email already verified");
          navigate("/login");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error checking verification status:", error);
        if (error.response && error.response.status === 500) {
          setLoading(false);
          setError(true);
        }
      }
    };

    fetchVerificationStatus();
  }, [navigate, params]);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoad(false);
    }, 1000);
  }, []);

  const handleVerify = async () => {
    const email = params.get("email");

    const trimmedOtp = otp.trim();

    // Check if trimmed OTP is empty
    if (!trimmedOtp) {
      toast.error("Please enter OTP");
      return;
    }
    if (!password && !confirmPassword) {
      toast.error("Please enter the password and confirm it");
      return;
    }
    if (password && !confirmPassword) {
      toast.error("Please confirm the password you entered");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const validatePassword = (password) => {
      const errors = [];

      if (!/(?=.*[a-z])/.test(password)) {
          errors.push("one lowercase letter");
      }
      if (password.length < 8) {
        errors.push("8 characters long");
    }
      if (!/(?=.*[A-Z])/.test(password)) {
          errors.push("one uppercase letter");
      }
      if (!/(?=.*\d)/.test(password)) {
          errors.push("one number");
      }
      if (!/(?=.*[!@#$%^&*()])/.test(password)) {
          errors.push("one symbol");
      }

      if (errors.length > 0) {
          toast.error(`Password must contain at least ${errors.join(", ")} `);
          return false;
      }

      return true;
  };

  if (!validatePassword(password)) {
      setLoading(false);
      return;
  }

    try {
      const payload = {
        email: email,
        otp: trimmedOtp,
        password: password,
      };
      setLoading(true);
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/verify-company-user`,
        payload
      );

      if (response.status === 200) {
        if (response.data === "Successfully verified counter user") {
          toast.success("User verified successfully");
          setTimeout(() => {
            navigate("/login");
            setLoading(false);
          }, 1000);
        } else {
          setLoading(false);
          toast.error(response.data);
        }
      } else {
        toast.error("Error verifying user");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error verifying user:", error.message);
      toast.error("Invalid otp");
      setLoading(false);
    }
  };

  if (initialLoad || loading) {
    return (
      <div className="loader-overlay">
        <div className="loader-content">
          <div className="loader-spinner"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h3>Account does not exists, please contact the administrator.</h3>
      </div>
    );
  }

  return (
    <div className="verify-counter-user">
      <div className="popup-overlay">
        <div className="popup-content">
          <h2 style={{ textAlign: "center" }}>Verify User</h2>
          <input
            className="edit-input"
            placeholder="Enter the OTP"
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <br />
          <input
            className="edit-input"
            placeholder="Enter the Email"
            type="mail"
            value={params.get("email")}
            readOnly
          />
          <br />
          <input
            className="edit-input"
            placeholder="Enter the Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <input
            className="edit-input"
            placeholder="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "15px 0",
              gap: "10px",
            }}
          >
            <button className="remove-btn" onClick={handleVerify}>
              Verify
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default UserVerfication;
