import axios from 'axios';
import ApiRoutes from './ApiRoutes';
import { isJwtExpired } from 'jwt-check-expiration';
import { handleLogout } from "./utils/index";

const axiosInstance = axios.create({
  baseURL: ApiRoutes.apiBaseUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      if (!isJwtExpired(token)) {
     
        config.headers.Authorization = token;
      } else {
        handleLogout();
        localStorage.removeItem('jwtToken');
        alert('Your session has expired. Please log in again.');
        window.location.href = '/';
       
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;


