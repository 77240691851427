import { createBrowserRouter, Navigate } from "react-router-dom";
import Register from "../Pages/Register";
import GenerateQR from "../Pages/GenerateQR";
import LoginPage from "../Pages/LoginPage";
import CompanyProfile from "../Pages/CompanyProfile";
import ViewQR from "../Pages/ViewQR";
import ViewQueue from "../Pages/ViewQueue";
import Users from "../Pages/Users";
import SamplePage from "../Pages/UserVerfication";
import UpdatePassword from "../Pages/Settings";


const isAuthenticated = () => {
  const token = localStorage.getItem("jwtToken");
  return token ? true : false;
};

const PrivateRoute = ({ element, path }) => {
  return isAuthenticated() ? (
    element
  ) : (
    <Navigate to="/" replace state={{ from: path }} />
  );
};


export const router = createBrowserRouter([
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/profile",
    element: <PrivateRoute element={<CompanyProfile />} path="/profile" />,
  },
  {
    path: "/users",
    element: <PrivateRoute element={<Users />} path="/users" />,
  },
  {
    path: "/generateqr",
    element: <PrivateRoute element={<GenerateQR />} path="/generateqr" />,
  },
  {
    path: "/viewqr",
    element: <PrivateRoute element={<ViewQR />} path="/viewqr" />,
  },
  {
    path: "/viewque",
    element: <PrivateRoute element={<ViewQueue />} path="/viewque" />,
  },
  {
    path: '/verify-counter-user',
    element: <SamplePage />
  },
  {
    path: "/updatepassword",
    element: <PrivateRoute element={<UpdatePassword />} path="/updatepassword" />,
  },

]);

