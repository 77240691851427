import axios, { HttpStatusCode } from "axios";
import { getMessaging, getToken } from "firebase/messaging";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiRoutes from "../ApiRoutes.js";
import logo1921 from "../Assets/logo1921.png";
import "../Styles/Register.css";
import axiosInstance from "../axiosInstance.js";

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    adminEmail: "",
    adminPassword: "",
    confirmPassword: "",
    businessSubtypeName: "Select Business type",
  });

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [businessSubtypes, setBusinessSubtypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLogOffPopUp, setShowLogOffPopUp] = useState(false);
  const [alreadyLoggedInUser, setAlreadyLoggedInUser] = useState("");

  const [selectedBusinessSubtypeId, setSelectedBusinessSubtypeId] =
    useState("");
  const [errorFCM, setErrorFCM] = useState(false);
  
  useEffect(() => {      
    axiosInstance
      .get("/company/business-subtypes")
      .then((response) => {
        const businessSubtypesData = response.data;
        setBusinessSubtypes(businessSubtypesData);
      })
      .catch((error) => {
        console.error("Error fetching business subtypes:", error);
      });
      fetchFcmToken();   
  }, []);

  const fetchFcmToken = async () => {
    let currentToken = null;
    try {
      const messaging = getMessaging();
      currentToken = await getToken(messaging, {
        vapidKey:
          "BElUngCUcirQzpKXGpgy4KPUdFKfgxpiAJQe8xVGOMlv-2irqk30qP-MX0In-q7Mu6iRLY3Gms_BPTLXWqC_ClU",
      });
      localStorage.setItem("fcmToken", currentToken);
    } catch (error) {      
      if (!errorFCM) {
        console.log(error.message);
        setErrorFCM(true);
        toast.error(
          "We noticed that your browser settings are blocking notifications. Queued.AI unfortunately doesn't work in private browsing mode."
        );
      }
    }
  }  
  
  const handleBlur = (e) => {
    if (e.target.name === "adminEmail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(formData.adminEmail);
      if (!isValidEmail) {
        toast.error("Please enter a valid email address");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "businessSubtypeName") {
      const selectedSubtype = businessSubtypes.find(
        (subtype) => subtype.businessSubTypeName === value
      );

      if (selectedSubtype) {
        setSelectedBusinessSubtypeId(selectedSubtype.businessSubTypeId);
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleVerifyEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(formData.adminEmail);

    if (!isValidEmail) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      // Check if the email is already registered
      setLoading(true);
      const checkEmailResponse = await axios.get(
        `${ApiRoutes.apiBaseUrl}/company/verify-email-registration/${formData.adminEmail}`
      );

      if (
        checkEmailResponse.status === 200 &&
        checkEmailResponse.data?.isRegistered
      ) {
        toast.error(
          "This email is already registered. Please use a different email."
        );
        setLoading(false);
        return;
      }

      // const messaging = getMessaging();
      // const currentToken = await getToken(messaging, {
      //   vapidKey:
      //     "BElUngCUcirQzpKXGpgy4KPUdFKfgxpiAJQe8xVGOMlv-2irqk30qP-MX0In-q7Mu6iRLY3Gms_BPTLXWqC_ClU",
      // });

      // const requestBody = {
      //   ...formData,
      //   fcmToken: currentToken,
      // };
      // const registerResponse = await axios.post(
      //   `${ApiRoutes.apiBaseUrl}${ApiRoutes.registerUser}`,
      //   requestBody,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );

      if (checkEmailResponse.status === 200) {
        await axios.post(`${ApiRoutes.apiBaseUrl}/company/send-otp`, {
          adminEmail: formData.adminEmail,
          adminPassword: formData.adminPassword,
          companyName: formData.companyName,
        });
        setLoading(false);
        setShowVerifyEmail(true);
        setShowOtpSection(true);
        toast.success("OTP sent to your email id. Please verify.");
      } else {
        toast.error("Registration failed. Please check your information.");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Email is already registered");
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      setLoading(true);
      // if (!otpValue) {
      //   toast.error("Please enter a valid OTP");
      //   setLoading(false);
      //   return;
      // }
      const trimmedOtp = otpValue.trim();

      // Check if trimmed OTP is empty
      if (!trimmedOtp) {
        toast.error("Please enter OTP");
        setLoading(false);
        return;
      }
      const response = await axios.post(
        `${ApiRoutes.apiBaseUrl}/company/email-verify`,
        {
          email: formData.adminEmail,
          otp: trimmedOtp,
        }
      );
      if (response.status === 200) {
        setIsVerified(true);
        setLoading(false);
        toast.success("Verification successful");
        setShowOtpSection(false);
      } else {
        toast.error("Verification failed");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);

      if (error.response.data === "Failed to verify OTP") {
        // Invalid OTP response from the API
        toast.error("Invalid OTP");
        setLoading(false);
      } else {
        // Other network or server error
        toast.error("Error verifying OTP. Please try again later.");
        setLoading(false);
      }
    }
  };

  const handleLogOffCancelPopUp = () => {
    console.log("Cancel log off pop-up triggered");
    setShowLogOffPopUp(false);
    // registration is success, so redirect to login page.
    navigate("/");
  };

  const handleLogOffPopUp = async () => {
    console.log("Handle log off pop triggered");
    // log off the user
    try {
      const email = alreadyLoggedInUser;
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/forceful-logoff`,
        email,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
      console.log("Forceful logout response:", response.data);
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);

      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized. Please log in again.");
      } else {
        toast.error(error.response.data);
      }
    } finally {
      setShowLogOffPopUp(false);
    }
  };

  const handleRegistration = async () => {
    if (!isVerified) {
      toast.error("Please verify your email before registering.");
      return;
    }
    if (!formData.adminPassword && !formData.confirmPassword) {
      toast.error("Please enter the password and confirm it");
      return;
    }
    if (formData.adminPassword && !formData.confirmPassword) {
      toast.error("Please confirm the password you entered");
      return;
    }

    if (formData.adminPassword !== formData.confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    if (formData.businessSubtypeName === "Select Business type") {
      toast.error("Please select a business type.");
      return;
    }
    if (formData.companyName.trim() === "") {
      toast.error("Please enter a company name.");
      return;
    }

    const validatePassword = (password) => {
      const errors = [];

      if (!/(?=.*[a-z])/.test(password)) {
        errors.push("one lowercase letter");
      }
      if (password.length < 8) {
        errors.push("8 characters long");
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        errors.push("one uppercase letter");
      }
      if (!/(?=.*\d)/.test(password)) {
        errors.push("one number");
      }
      if (!/(?=.*[!@#$%^&*()])/.test(password)) {
        errors.push("one symbol");
      }

      if (errors.length > 0) {
        toast.error(`Password must contain at least ${errors.join(", ")} `);
        return false;
      }

      return true;
    };

    if (!validatePassword(formData.adminPassword)) {
      setLoading(false);
      return;
    }

    try {
      let currentToken = localStorage.getItem("fcmToken");
      if (null === currentToken) {
        try {
          const messaging = getMessaging();
          currentToken = await getToken(messaging, {
            vapidKey:
              "BElUngCUcirQzpKXGpgy4KPUdFKfgxpiAJQe8xVGOMlv-2irqk30qP-MX0In-q7Mu6iRLY3Gms_BPTLXWqC_ClU",
          });
          localStorage.setItem("fcmToken", currentToken);
        } catch (error) {
          console.log(error.message);
          toast.error(
            "We noticed that your browser settings are blocking notifications. Queued.AI unfortunately doesn't work in private browsing mode."
          );
          return;
        }
      }
      const requestBody = {
        ...formData,
        businessSubTypeId: selectedBusinessSubtypeId,
        fcmToken: currentToken,
      };
      setLoading(true);
      const response = await axios.post(
        `${ApiRoutes.apiBaseUrl}${ApiRoutes.registerUser}`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let jwtToken = response.data.jwt;
      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem("userRole", "companyAdmin");
      // let companyUserId = response.data.companyUserId;
      // localStorage.setItem("companyUserId", companyUserId);

      const companyResponse = await axiosInstance.get(
        `${ApiRoutes.apiBaseUrl}/company/0`
      );
      const { companyId: fetchedCompanyId } = companyResponse.data;

      if (fetchedCompanyId !== undefined && fetchedCompanyId !== null) {
        localStorage.setItem("companyId", fetchedCompanyId);
      }

      const { queues } = companyResponse.data;
      if (queues && queues.length > 0) {
        const queueCode = queues[0].queueCode;
        localStorage.setItem("queueCode", queueCode);
      }
      localStorage.setItem(
        "companyDetails",
        JSON.stringify(companyResponse.data)
      );

      if (response.status === 200) {
        toast.success("Registration successful!", {
          onClose: () => {
            setTimeout(() => {
              navigate("/generateqr");
            }, 2000);
          },
        });
        setLoading(false);
      } else {
        toast.error("Registration failed. Please check your information.");
        setLoading(false);
      }
    } catch (error) {
      if (error.response.status === HttpStatusCode.Conflict) {
        const match = error.response.data.alreadyLoggedInUserEmail;
        setAlreadyLoggedInUser(match);
        setShowLogOffPopUp(true);
      } else if (error.response && error.response.status === 500) {
        toast.error(error.response.data);
      } else {
        toast.error("An error occurred during registration.");
      }
      setLoading(false);
    }
  };

  return (
    <div className="main-container">
      <div className="register-container">
        {/* Loader */}
        {loading && (
          <div className="loader-overlay">
            <div className="loader-content">
              <div className="loader-spinner"></div>
              {/* <p className="loading-text">Loading . . .</p> */}
            </div>
          </div>
        )}
        <div className="register-content">
          <div className="welcome">
            <img className="header-image" src={logo1921} alt="Your Logo" />
          </div>
          <div className="register-form">
            <label className="register-label">Company Name</label>
            <input
              className="register-input"
              type="text"
              name="companyName"
              placeholder="Company name"
              onChange={handleChange}
            />
          </div>
          <div className="register-form">
            <label className="register-label">Business type</label>
            <select
              className="register-input1"
              name="businessSubtypeName"
              value={formData.businessSubtypeName}
              onChange={handleChange}
            >
              <option disabled value="Select Business type">
                Select Business type
              </option>
              {businessSubtypes &&
                businessSubtypes.map((subtype) => (
                  <option
                    key={subtype.businessSubTypeId}
                    value={subtype.businessSubTypeName}
                  >
                    {subtype.businessSubTypeName}
                  </option>
                ))}
            </select>
          </div>
          <div className="register-form">
            <label className="register-label">Email</label>
            <input
              className="register-input"
              type="email"
              name="adminEmail"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              readOnly={isVerified}
            />
            <div className="tick-container">
              {isVerified && (
                <div className="tick-mark" style={{ color: "green" }}>
                  &#10004;
                </div>
              )}
              {!showVerifyEmail && (
                <button className="verify-button" onClick={handleVerifyEmail}>
                  Verify
                </button>
              )}
            </div>
            {!isVerified && (
              <div className="verify-text">verify email before registering</div>
            )}
            {showVerifyEmail && (
              <div
                className="otp-section"
                style={{ display: showOtpSection ? "block" : "none" }}
              >
                <label className="register-label">OTP</label>
                <input
                  className="register-input"
                  type="text"
                  placeholder="Enter OTP"
                  value={otpValue}
                  onChange={(e) => setOtpValue(e.target.value)}
                />
                <button className="submit-otp-button" onClick={handleOtpSubmit}>
                  Submit
                </button>
              </div>
            )}
          </div>
          <div className="register-form">
            <label className="register-label">Password</label>
            <input
              className="register-input"
              type="password"
              name="adminPassword"
              placeholder="Password"
              onChange={handleChange}
            />
          </div>
          <div className="register-form">
            <label className="register-label">Confirm Password</label>
            <input
              className="register-input"
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={handleChange}
            />
          </div>
          <div className="registerButton-section">
            <button
              type="submit"
              className="register-button"
              onClick={handleRegistration}
            >
              Register
            </button>
            <button className="cancel-button" onClick={() => navigate("/")}>
              Cancel
            </button>
          </div>

          {/* log off user popup */}
          {showLogOffPopUp && (
            <div className="popup-overlay">
              <div className="popup-content">
                {loading && (
                  <div className="loader-overlay">
                    <div className="loader-content">
                      <div className="loader-spinner"></div>
                      <p className="loading-text">Loading...</p>
                    </div>
                  </div>
                )}
                <h2 style={{ color: "green" }}>Registration completed.</h2>
                <p className="delete-popup-para">
                  <h3 style={{ color: "gold" }}>Session conflict</h3>
                  We found another active session <b>
                    {alreadyLoggedInUser}
                  </b>{" "}
                  that conflicts to launch the dashboard. Would you like to log
                  out the other session now?
                </p>
                <button
                  style={{ marginRight: "10px" }}
                  className="inform-btn"
                  onClick={handleLogOffCancelPopUp}
                >
                  Cancel
                </button>
                <button className="remove-btn" onClick={handleLogOffPopUp}>
                  Yes
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Register;
