import { getMessaging, getToken } from "firebase/messaging";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import packageJson from "../../package.json";
import ApiRoutes from "../ApiRoutes.js";
import logo1921 from "../Assets/logo1921.png";
import "../Styles/LoginPage.css";
import axiosInstance from "../axiosInstance.js";
import { HttpStatusCode } from "axios";

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showNewPasswordFields, setShowNewPasswordFields] = useState(false);
  const [forgetPasswordClicked, setForgetPasswordClicked] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otpLogin, setOtpLogin] = useState();
  const [selectedCounter, setSelectedCounter] = useState(null);
  const [availableCounter, setAvailableCounter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLogOffPopUp, setShowLogOffPopUp] = useState(false);
  const [alreadyLoggedInUser, setAlreadyLoggedInUser] = useState("");

  // eslint-disable-next-line
  const [locationEnabled, setLocationEnabled] = useState(true);
  const { version } = packageJson;
  const { REACT_APP_PROFILE } = process.env;
  const isInitialMount = useRef(true);
  let companyUserId = null;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!navigator.geolocation) {
      setLocationEnabled(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      () => {
        setLocationEnabled(true);
      },
      (error) => {
        setLocationEnabled(false);
        console.error("Geolocation error:", error);
        window.alert(
          "To make the most of your experience, this website requires location service to be turned on. Follow these simple steps:\n\n1. Click on the Location icon located next to the URL in your browser.\n2. Choose 'Allow' to ensure you receive timely updates."
        );
      }
    );
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedCounter !== null) {
        try {
          await handleSelectCounterClick(companyUserId, selectedCounter);
        } catch (error) {
          console.error("Error handling selected counter click:", error);
        }
      }
    };

    fetchData();
  }, [selectedCounter, companyUserId]);

  const handleLoginClick = async () => {
    let messaging,
      currentToken,
      loginData = null;
    try {
      messaging = getMessaging();
      currentToken = await getToken(messaging, {
        vapidKey:
          "BElUngCUcirQzpKXGpgy4KPUdFKfgxpiAJQe8xVGOMlv-2irqk30qP-MX0In-q7Mu6iRLY3Gms_BPTLXWqC_ClU",
      });
      localStorage.setItem("fcmToken", currentToken);
      loginData = {
        adminEmail: email,
        adminPassword: password,
        fcmToken: currentToken,
      };
    } catch (error) {
      console.log(error.message);
      toast.error(
        "We noticed that your browser settings are blocking notifications. Queued.AI unfortunately doesn't work in private browsing mode."
      );
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (!password.trim()) {
      toast.error("Please enter your password");
      return;
    }
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}${ApiRoutes.loginUser}`,
        loginData,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        const { avlCounters, jwtToken, userRole, companyUserId, counter } =
          response.data;
        localStorage.setItem("jwtToken", jwtToken);
        localStorage.setItem("companyUserId", companyUserId);
        localStorage.setItem("userRole", userRole);

        const companyResponse = await axiosInstance.get(
          `${ApiRoutes.apiBaseUrl}/company/0`
        );
        const { companyId: fetchedCompanyId } = companyResponse.data;
        setLoading(false);
        if (fetchedCompanyId !== undefined && fetchedCompanyId !== null) {
          localStorage.setItem("companyId", fetchedCompanyId);
        }

        const { queues, workFlows } = companyResponse.data;
        if (queues && queues.length > 0) {
          const queueCode = queues[0].queueCode;
          localStorage.setItem("queueCode", queueCode);
        }
        localStorage.setItem(
          "companyDetails",
          JSON.stringify(companyResponse.data)
        );

        if (userRole === "counterUser") {
          setShowPopup(true);
          toast.success(counter ? "Login successful!" : "Login successful!");
          const firstWorkFlowId = workFlows[0].wfId;
          localStorage.setItem("workflowId", firstWorkFlowId);

          if (counter !== null) {
            setSelectedCounter(counter.cid);
            setShowPopup(false);
          } else if (avlCounters.length === 1) {
            setSelectedCounter(avlCounters[0].cid);
            setShowPopup(false);
          }

          setAvailableCounter(counter ? [counter] : avlCounters);
          console.log(counter ? availableCounter : avlCounters);
        } else if (userRole === "companyAdmin") {
          if (queues.length > 0) {
            const firstWorkFlowId = workFlows[0].wfId;
            localStorage.setItem("workflowId", firstWorkFlowId);
            navigate("/viewque");
          } else {
            navigate("/viewque");
          }
        } else {
          navigate("/generateqr");
          toast.success("Login successful, create your queue!");
        }
      } else {
        console.error("Login failed");
        setLoading(false);
        toast.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      if (error.response.status === HttpStatusCode.Conflict) {
        // only applicable for admin users. // different system
        setAlreadyLoggedInUser(loginData.adminEmail);
        setShowLogOffPopUp(true);
      } else if (error.response.status === HttpStatusCode.Locked) {
        // from the same system
        const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
        const match = error.response.data.match(regex);
        setAlreadyLoggedInUser(match[0]);
        setShowLogOffPopUp(true);
      } else if (
        error.response &&
        error.response.status === 500 &&
        (error.response.data === "All counters are engaged." ||
          error.response.data === "Invalid email id" ||
          error.response.data === "Please verify the email address" ||
          error.response.data ===
            "You are already logged in from another device, please log off from all other devices and try again." ||
          error.response.data.includes(
            "Another user already logged in from this system, logoff the existing user and try again."
          ) ||
          error.response.data.includes("Access to the system"))
      ) {
        if (error.response.data === "All counters are engaged.") {
          toast.error("All counters are engaged.");
        } else if (error.response.data === "Invalid email id") {
          toast.error("Email is not registered.");
        } else if (error.response.data === "Please verify the email address") {
          toast.error("Please verify the email address");
        } else if (error.response.data.includes("Access to the system")) {
          toast.error(error.response.data);
        } else if (
          error.response.data ===
          "You are already logged in from another device, please log off from all other devices and try again."
        ) {
          toast.error(error.response.data);
        } else {
          toast.error(error.response.data);
        }
      } else {
        console.error("Error:", error);
        toast.error("Wrong credentials");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogOffCancelPopUp = () => {
    setShowLogOffPopUp(false);
  };

  const handleLogOffPopUp = async () => {
    console.log("Handle log off pop triggered");
    // log off the user
    try {
      const email = alreadyLoggedInUser;
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/forceful-logoff`,
        email,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
      console.log("Forceful logout response:", response.data);
      toast.success("Forceful logout successful");
      toast.success(
        "A conflict with your login has been resolved. Please log in again."
      );
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);

      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized. Please log in again.");
      } else {
        toast.error(error.response.data);
      }
    } finally {
      setShowLogOffPopUp(false);
    }
  };

  const handleSelectCounterClick = async () => {
    if (!selectedCounter) {
      console.error("Please select a counter.");
      return;
    }
    setLoading(true);
    const companyUserId = localStorage.getItem("companyUserId");

    try {
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/counter-assign`,
        {
          companyUserId: companyUserId,
          counterId: selectedCounter,
        }
      );

      if (response.status === 200) {
        const { cno, cname, cid } = response.data;

        localStorage.setItem("usercid", cid);
        localStorage.setItem("cno", cno);
        localStorage.setItem("cname", cname);
        localStorage.setItem("userEmail", email);

        setShowPopup(false);
        toast.success("Counter assigned successfully!");
        setLoading(false);
        navigate("/viewque");
      } else {
        toast.error("Failed to assign counter. Please try again.");
      }
    } catch (error) {
      console.error("Error assigning counter:", error);
      toast.error("Error assigning counter. Please try again later.");
      setLoading(false);
    }
  };

  const [major, minor, build] = version.split(".");

  const paddedMinor = minor.padStart(2, "0");
  const paddedBuild = build.padStart(2, "0");

  const versionString = `${
    REACT_APP_PROFILE || "dev"
  }v${major}.${paddedMinor}.${paddedBuild}`;
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLoginClick();
    }
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgetPasswordClick = async () => {
    setShowPassword(false);
    setShowOtp(false);
    setShowForgetPassword(true);
    setForgetPasswordClicked(true);
  };
  const handleCloseButton = async () => {
    setShowPassword(true);
    setShowOtp(false);
    setShowForgetPassword(false);
    setForgetPasswordClicked(false);
    setShowNewPasswordFields(false);
  };

  const handleSendOtp = async () => {
    if (!email.trim()) {
      toast.error("Please provide an email address.");
      return;
    }
    try {
      setLoading(true);
      // Make API call to check verification status
      const response1 = await axiosInstance.get(
        `company/company-user-role/${email}`
      );

      if (response1.data.userStatus === 0) {
        toast.error("Please verify the email address");
        setLoading(false);
        return;
      }

      const forgetPasswordData = {
        adminEmail: email,
      };

      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/send-otp`,
        forgetPasswordData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setForgetPasswordClicked(true);
        setShowForgetPassword(false);
        setShowOtp(true);
        setLoading(false);
        toast.success("OTP sent successfully!");
      } else {
        toast.error("Failed to send OTP. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      // Check if the error status is 500 (email not registered)
      if (error.response && error.response.status === 500) {
        toast.error("Email is not registered.");
        setLoading(false);
      } else {
        console.error("Error sending OTP:", error);
        toast.error("Error sending OTP. Please try again later.");
        setLoading(false);
      }
    }
  };

  const handleForgetPasswordSubmit = async () => {
    const trimmedOtp = otp.trim();

    if (!trimmedOtp) {
      toast.error("Please enter the OTP to verify");
      return;
    }
    try {
      const otpData = {
        email: email,
        otp: trimmedOtp,
        verified: true,
      };
      setOtpLogin(otp);

      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/verify-otp`,
        otpData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setShowForgetPassword(false);
        setShowNewPasswordFields(true);
        setShowOtp(false);
        toast.success("OTP verified successfully!");
      } else {
        toast.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);

      if (error.response.data === "Failed to verify OTP") {
        // Invalid OTP response from the API
        toast.error("Invalid OTP");
      } else {
        // Other network or server error
        toast.error("Error verifying OTP. Please try again later.");
      }
    }
  };

  const handleNewPasswordSubmit = async () => {
    if (!newPassword && !confirmPassword) {
      toast.error("Please enter the password and confirm it ");
      return;
    }
    if (newPassword && !confirmPassword) {
      toast.error("Please confirm the password you entered");

      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match. Please re-enter.");
      return;
    }
    const validatePassword = (password) => {
      const errors = [];

      if (!/(?=.*[a-z])/.test(password)) {
        errors.push("one lowercase letter");
      }
      if (password.length < 8) {
        errors.push("8 characters long");
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        errors.push("one uppercase letter");
      }
      if (!/(?=.*\d)/.test(password)) {
        errors.push("one number");
      }
      if (!/(?=.*[!@#$%^&*()])/.test(password)) {
        errors.push("one symbol");
      }

      if (errors.length > 0) {
        toast.error(`Password must contain at least  ${errors.join(", ")} `);
        return false;
      }

      return true;
    };

    if (!validatePassword(newPassword)) {
      setLoading(false);
      return;
    }
    try {
      // Make API call to fetch user role
      setLoading(true);
      const userRoleResponse = await axiosInstance.get(
        `${ApiRoutes.apiBaseUrl}/company/company-user-role/${email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check user role obtained from the response
      if (userRoleResponse.status === 200) {
        const userRoleName = userRoleResponse.data.userRoleName;
        const companyUserId = userRoleResponse.data.companyUserId;

        // Decide which API call to make based on user role
        if (userRoleName === "ADMIN") {
          const registerData = {
            adminEmail: email,
            adminPassword: newPassword,
            companyName: "YourCompanyName",
          };

          const response = await axiosInstance.post(
            `${ApiRoutes.apiBaseUrl}/company/register`,
            registerData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            toast.success("Admin password changed successfully!");
            setShowNewPasswordFields(false);
            setShowForgetPassword(false);
            setShowPassword(true);
            setForgetPasswordClicked(false);
            setLoading(false);
          } else {
            toast.error("Registration failed. Please try again.");
            setLoading(false);
          }
        } else if (userRoleName === "COUNTER_USER") {
          const payload = {
            companyUserId: companyUserId,
            email: email,
            companyId: "0",
            otp: otpLogin,
            password: newPassword,
          };
          // Make API call to update password
          const response = await axiosInstance.post(
            "/company/verify-company-user",
            payload
          );

          if (response.status === 200) {
            toast.success("User password updated successfully!");
            setShowNewPasswordFields(false);
            setShowForgetPassword(false);
            setShowPassword(true);
            setForgetPasswordClicked(false);
            setLoading(false);
          } else {
            toast.error("Failed to update password. Please try again.");
            setLoading(false);
          }
        } else {
          // Handle other user roles if needed
        }
      } else {
        toast.error("Failed to fetch user role. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error. Please try again later.");
      setLoading(false);
    }
  };

  // eslint-disable-next-line

  return (
    <div className="login-page">
      <div className="login-container">
        {loading && (
          <div className="loader-overlay">
            <div className="loader-content">
              <div className="loader-spinner"></div>
              {/* <p className="loading-text">Loading . . .</p> */}
            </div>
          </div>
        )}
        <div>
          <div style={{ textAlign: "center" }}>
            <img className="header-image" src={logo1921} alt="Your Logo" />
          </div>
          {showForgetPassword && (
            <label className="forget-password-title">Forgot Password</label>
          )}
          <input
            className="login-input"
            type="email"
            id="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {showPassword && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              <input
                className="login-input"
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <span
                className="password-toggle-icon"
                onClick={handleTogglePasswordVisibility}
              ></span>
            </div>
          )}
          {showForgetPassword && (
            <div>
              <button
                type="button"
                className="forget-btn"
                onClick={handleSendOtp}
              >
                Send OTP
              </button>
            </div>
          )}

          {showOtp && (
            <div>
              <input
                className="login-input"
                type="text"
                id="otp"
                name="otp"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <button
                type="button"
                className="forget-btn"
                onClick={handleForgetPasswordSubmit}
              >
                Submit OTP
              </button>
            </div>
          )}

          {showNewPasswordFields && (
            <div className="password-div">
              <input
                className="login-input"
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                className="login-input"
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                type="button"
                className="submit-btn"
                onClick={handleNewPasswordSubmit}
              >
                Submit password
              </button>
            </div>
          )}
          {!forgetPasswordClicked && (
            <Link
              className="forget-password"
              onClick={handleForgetPasswordClick}
            >
              Forgot Password?
            </Link>
          )}
          {!forgetPasswordClicked ? (
            <div className="button-section">
              <button
                type="button"
                className="login-button"
                onClick={handleLoginClick}
              >
                Login
              </button>
              <span className="register-span">
                Don't have an account?
                <Link className="register-link" to="/register">
                  Register
                </Link>
              </span>
            </div>
          ) : (
            <div>
              {/* Render close button or any other desired UI */}
              <button
                className="close-btn-login"
                type="button"
                onClick={handleCloseButton}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
        {/* log off user popup */}
        {showLogOffPopUp && (
          <div className="popup-overlay">
            <div className="popup-content">
              {loading && (
                <div className="loader-overlay">
                  <div className="loader-content">
                    <div className="loader-spinner"></div>
                    <p className="loading-text">Loading...</p>
                  </div>
                </div>
              )}
              <h2>Login Conflict</h2>
              <p className="delete-popup-para">
                We found another active session that conflicts with this login
                attempt. Would you like to log out the other session now?
              </p>
              <button
                style={{ marginRight: "10px" }}
                className="inform-btn"
                onClick={handleLogOffCancelPopUp}
              >
                Cancel
              </button>
              <button className="remove-btn" onClick={handleLogOffPopUp}>
                Yes
              </button>
            </div>
          </div>
        )}
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content scale-up">
            {loading && (
              <div className="loader-overlay">
                <div className="loader-content">
                  <div className="loader-spinner"></div>
                  <p className="loading-text">Loading . . .</p>
                </div>
              </div>
            )}
            <h2 className="popup-title">Select Counter</h2>

            {/* Add a dropdown to select counters */}
            <div className="counter-dropdown">
              <select
                className="counter-Select"
                id="counterSelect"
                onChange={(e) => setSelectedCounter(e.target.value)}
              >
                <option
                  value=""
                  disabled
                  selected
                  style={{ textAlign: "left" }}
                >
                  Choose a counter
                </option>
                {availableCounter
                  .slice()
                  .sort((a, b) => a.cno - b.cno)
                  .map((counter) => (
                    <option
                      key={counter.cid}
                      value={counter.cid}
                      style={{ textAlign: "left" }}
                    >
                      {counter.cno} - {counter.cname}
                    </option>
                  ))}
              </select>
            </div>

            <div className="button-flex">
              {/* <button
                className="create-queue-button"
                onClick={handleSelectCounterClick}
                disabled={!selectedCounter}
              >
                Open Counter
              </button> */}
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
      <p className="version-number">{versionString}</p>
    </div>
  );
}

export default LoginPage;
