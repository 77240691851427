import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../Components/Header";
import NavBar from "../Components/Navbar";
import ApiRoutes from "../ApiRoutes.js";
import axiosInstance from "../axiosInstance.js";
import { toast, ToastContainer } from "react-toastify";
import "../Styles/Users.css";
import logout from "../Assets/logout.svg";



function Users() {
  const [showAddUserPopup, setShowAddUserPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [showEditUserPopup, setShowEditUserPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedUser, setEditedUser] = useState({
    userName: "",
    userMail: "",
    phoneNo: "",
  });
  const [loading, setLoading] = useState(false);
  const [logOffState, setLogOffState] = useState(false);
  const [logOffUserEmail, setLogOffUserEmail] = useState("");

  useEffect(() => {
    // Fetch and update the list of users when the component mounts
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/get-all-company-users`
      );

      if (response.data) {
        setUsers(response.data);
        setLoading(false);
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  //   Add user
  const handleShowAddUserPopup = () => {
    setShowAddUserPopup(true);
  };

  const handleCloseAddUserPopup = () => {
    setShowAddUserPopup(false);
  };

  const handleCloseLogOffPopUp = () => {
    setLogOffState(false);
  };

  const handleAddUser = async () => {
    try {
      const userName = document.getElementById("userName").value;
      const email = document.getElementById("email").value;
      const mobileNumber = document.getElementById("mobilenumber").value;
      const companyId = localStorage.getItem("companyId");
      // Validate input fields
      if (!userName.trim()) {
        toast.error("Please provide your user name");
        return;
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email.trim() || !emailRegex.test(email)) {
        toast.error("Please enter a valid email address");
        return;
      }

      // If mobile number is provided, validate its format
      if (mobileNumber) {
        const mobileRegex = /^\d{10,15}$/;
        if (!mobileRegex.test(mobileNumber)) {
          toast.error("Please enter a mobile number with 10 to 15 digits!");
          return;
        }
      }
      setLoading(true);

      const payload = {
        companyId: companyId,
        email: email,
        phoneNo: mobileNumber,
        userName: userName,
      };

      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/create-company-user`,
        payload
      );
      if (response.status === 200) {
        toast.success("OTP sent to the user.");
        handleCloseAddUserPopup();
        setLoading(true);
        try {
          const response = await axiosInstance.post(
            `${ApiRoutes.apiBaseUrl}/company/get-all-company-users`
          );

          if (response.data) {
            setUsers(response.data);
            setLoading(false);
          } else {
            console.error("Failed to fetch users");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      } else {
        console.error("Failed to add user");
        toast.error(response.data);
      }
    } catch (error) {
      console.error("Error adding user:", error);
      if (error.response && error.response.status === 500) {
        console.error("Failed to add user");
        toast.error("Email already registered");
        setLoading(false);
      }
    }
  };
  const sortedUsers = users
    .slice()
    .sort((a, b) => a.companyUserId - b.companyUserId);

  // Delete User
  const handleShowDeletePopup = (companyUserId) => {
    setShowDeletePopup(true);
    setSelectedUserId(companyUserId);
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };

  const handleForcefullLogOffPopUp = (email) => {
    setLogOffState(true);
    setLogOffUserEmail(email);
  };

  const handleForceLogout = async (userEmail) => {
    try {
      const email = userEmail;
      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/forceful-logoff`,
        email,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
      console.log("Forceful logout response:", response.data);
      toast.success("Forceful logout successful");
      fetchUsers();
    } catch (error) {
      console.error("Error during logout:", error);

      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized. Please log in again.");
      } else {
        toast.error("Failed to logout. Please try again.");
      }
    } finally {
      setLogOffState(false);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const payload = {
        companyUserId: selectedUserId,
      };
      setLoading(true);

      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/delete-company-user`,
        payload
      );

      handleCloseDeletePopup();

      if (response.status === 200) {
        try {
          const response = await axiosInstance.post(
            `${ApiRoutes.apiBaseUrl}/company/get-all-company-users`
          );

          if (response.data) {
            setUsers(response.data);
            setLoading(false);
          } else {
            console.error("Failed to fetch users");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
        setLoading(false);
        toast.success("User deleted successfully");
        console.log(response);
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      if (error.response && error.response.status === 500) {
        toast.error(
          "To delete the user, please log out the current user of the counter."
        );
        setLoading(false);
        handleCloseDeletePopup();
      }
    }
    // Close the popup after API call
  };

  // Edit User
  const handleShowEditUserPopup = (user) => {
    setSelectedUser(user);
    setEditedUser({
      userName: user.userName,
      email: user.email,
      phoneNo: user.phoneNo,
    });
    setShowEditUserPopup(true);
  };

  const handleCloseEditUserPopup = () => {
    setShowEditUserPopup(false);
  };

  const handleUpdateUser = async () => {
    const { companyUserId } = selectedUser;

    // Validate input fields
    if (!editedUser.userName) {
      toast.error("Please provide your user name");
      return;
    }

    // If mobile number is provided, validate its format
    if (editedUser.phoneNo) {
      const mobileRegex = /^\d{10,15}$/;
      if (!mobileRegex.test(editedUser.phoneNo)) {
        toast.error("Please enter a mobile number with 10 to 15 digits!");
        return;
      }
    }

    try {
      const payload = {
        companyUserId,
        phoneNo: editedUser.phoneNo,
        userName: editedUser.userName,
      };
      setLoading(true);

      const response = await axiosInstance.post(
        `${ApiRoutes.apiBaseUrl}/company/update-company-user`,
        payload
      );

      if (response.status === 200) {
        handleCloseEditUserPopup();
        try {
          const response = await axiosInstance.post(
            `${ApiRoutes.apiBaseUrl}/company/get-all-company-users`
          );

          if (response.data) {
            setUsers(response.data);
            toast.success("User updated successfully");
            setLoading(false);
          } else {
            console.error("Failed to fetch users");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
        setLoading(false);
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div>
      <div style={{ marginTop: "3rem" }}>
        <Header />
      </div>
      <NavBar />
      <div>
        {loading && (
          <div className="loader-overlay">
            <div className="loader-content">
              <div className="loader-spinner"></div>
              <p className="loading-text">Loading . . .</p>
            </div>
          </div>
        )}
        <div className="profile-title">
          <h3 style={{ paddingTop: "1.5rem" }}>
            <span>Counter Users</span>
          </h3>
        </div>
        <hr className="hr-profile" />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <div className="users-table">
            <div>
              <table
                style={{
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
              >
                <thead style={{ backgroundColor: "black", color: "white" }}>
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        backgroundColor: "#3d4493",
                        textAlign: "center",
                        width: "200px",
                        borderRight: "1px solid white",
                      }}
                    >
                      Users
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        backgroundColor: "#3d4493",
                        textAlign: "center",
                        width: "200px",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers.length === 0 ? (
                    <tr>
                      <td
                        colSpan="2"
                        style={{
                          padding: "10px",
                          border: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        No users were added
                      </td>
                    </tr>
                  ) : (
                    sortedUsers.map((user) => (
                      <tr key={user.userId}>
                        <td
                          style={{
                            padding: "10px",
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                        >
                          {user.userName}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              margin: "0",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <button
                                className="edit-icon-counter"
                                onClick={() => handleShowEditUserPopup(user)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                              <button
                                className="delete-icon-counter"
                                onClick={() =>
                                  handleShowDeletePopup(user.companyUserId)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                            {user.userStatus === 2 && (
                              <img
                                src={logout}
                                onClick={() =>
                                  handleForcefullLogOffPopUp(user.email)
                                }
                                alt="SG Icon"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "6px",
                                  cursor: "pointer",
                                }}
                                title="Click to Logout from all device"
                              />
                            )}
                            <div>
                              {user.userStatus !== 0 && (
                                <div
                                  style={{ color: "green" }}
                                  className="tooltip"
                                >
                                  <FontAwesomeIcon icon={faCheckCircle} />
                                  <span className="tooltip-text">Verified</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <button
                className="remove-btn"
                style={{ marginTop: "22px" }}
                onClick={handleShowAddUserPopup}
              >
                Add New user
              </button>
            </div>
          </div>

          {/* "Add New User" Popup */}
          {showAddUserPopup && (
            <div className="popup-overlay">
              <ToastContainer />
              <div className="popup-content">
                {loading && (
                  <div className="loader-overlay">
                    <div className="loader-content">
                      <div className="loader-spinner"></div>
                      <p className="loading-text">Loading . . .</p>
                    </div>
                  </div>
                )}
                <h2 style={{ textAlign: "center" }}>Add New User</h2>
                <input
                  className="edit-input"
                  placeholder="User Name"
                  type="text"
                  id="userName"
                />
                <br />
                <input
                  className="edit-input"
                  placeholder="Email"
                  type="mail"
                  id="email"
                />
                <br />
                <input
                  className="edit-input"
                  placeholder="Mobile Number"
                  type="number"
                  id="mobilenumber"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "15px 0",
                    gap: "10px",
                  }}
                >
                  <button
                    className="cancel-btn"
                    onClick={handleCloseAddUserPopup}
                  >
                    Cancel
                  </button>
                  <button className="remove-btn" onClick={handleAddUser}>
                    Add User
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Edit User */}
          {showEditUserPopup && selectedUser && (
            <div className="popup-overlay">
              <ToastContainer />
              <div className="popup-content">
                {loading && (
                  <div className="loader-overlay">
                    <div className="loader-content">
                      <div className="loader-spinner"></div>
                      <p className="loading-text">Loading...</p>
                    </div>
                  </div>
                )}
                <h2 style={{ textAlign: "center" }}>Edit User</h2>
                <input
                  className="edit-input"
                  type="text"
                  id="userName"
                  placeholder="User Name"
                  value={editedUser.userName}
                  onChange={(e) =>
                    setEditedUser((prev) => ({
                      ...prev,
                      userName: e.target.value,
                    }))
                  }
                />
                <br />
                <input
                  className="edit-input"
                  type="email"
                  id="userMail"
                  placeholder="User Mail"
                  defaultValue={selectedUser.email}
                  readOnly
                />
                <input
                  className="edit-input"
                  type="tel"
                  id="phoneNo"
                  placeholder="Mobile Number"
                  value={editedUser.phoneNo}
                  onChange={(e) =>
                    setEditedUser((prev) => ({
                      ...prev,
                      phoneNo: e.target.value,
                    }))
                  }
                />
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "15px 0pc",
                    gap: "10px",
                  }}
                >
                  <button
                    className="cancel-btn"
                    onClick={handleCloseEditUserPopup}
                  >
                    Cancel
                  </button>
                  <button className="remove-btn" onClick={handleUpdateUser}>
                    Update User
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Delete user popup */}
          {showDeletePopup && (
            <div className="popup-overlay">
              <div className="popup-content">
                {loading && (
                  <div className="loader-overlay">
                    <div className="loader-content">
                      <div className="loader-spinner"></div>
                      <p className="loading-text">Loading...</p>
                    </div>
                  </div>
                )}
                <h2>Are you sure?</h2>
                <p className="delete-popup-para">
                  Do you wish to remove this user? If so, please ensure that all
                  ongoing services are concluded before proceeding.
                </p>
                <button
                  style={{ marginRight: "10px" }}
                  className="inform-btn"
                  onClick={handleCloseDeletePopup}
                >
                  Cancel
                </button>
                <button className="remove-btn" onClick={handleDeleteUser}>
                  Delete User
                </button>
              </div>
            </div>
          )}

          {/* Log out user popup */}
          {logOffState && (
            <div className="popup-overlay">
              <div className="popup-content">
                {loading && (
                  <div className="loader-overlay">
                    <div className="loader-content">
                      <div className="loader-spinner"></div>
                      <p className="loading-text">Loading...</p>
                    </div>
                  </div>
                )}
                <h2>Are you sure?</h2>
                <p className="delete-popup-para">
                  Do you wish to log out this user from all devices?
                </p>
                <button
                  style={{ marginRight: "10px" }}
                  className="inform-btn"
                  onClick={handleCloseLogOffPopUp}
                >
                  Cancel
                </button>
                <button
                  className="remove-btn"
                  onClick={() => handleForceLogout(logOffUserEmail)}
                >
                  Log out user
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Users;
