export const handleLogout = () => {
    localStorage.removeItem("companyDetails");
    localStorage.removeItem("queues");
    localStorage.removeItem("queueCode");
    localStorage.removeItem("workflowId");
    localStorage.removeItem("companyId");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("qrCodeUrl");
    localStorage.removeItem("subtypeId");
    localStorage.removeItem("cid");
    localStorage.removeItem("companyUserId");
    localStorage.removeItem("userRole");
    localStorage.removeItem("usercid");
    localStorage.removeItem("cno");
    localStorage.removeItem("cname");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("fcmToken");
}
